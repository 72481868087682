import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';

const VolunteerSignup = () => {
    return (
        <div>
            <>
                <SEO title="Volunteer Pledge" />
                <MDBContainer className="mt-4">
                    <h3 className="title font-weight-bolder text-secondary">Volunteer Pledge</h3>
                    <Pageheader />
                    <iframe title="volunteer" src="https://docs.google.com/forms/d/e/1FAIpQLSefmzHd3X7mAaG01s_cBSN1ZbBRL9VV2DI_A2zB-uZJnfnEbw/viewform?embedded=true" width="100%" style={{ minHeight: "2000px" }} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </MDBContainer>
            </>
        </div>
    );
};

export default VolunteerSignup;